/* eslint-disable max-len, no-param-reassign, no-unused-vars */
/**
 * Air theme JavaScript.
 */

// Import modules
import reframe from 'reframe.js';
import { styleExternalLinks, initExternalLinkLabels } from './modules/external-link';
import initAnchors from './modules/anchors';
//import backToTop from './modules/top';
import initA11ySkipLink from './modules/a11y-skip-link';
import './modules/navigation';
// import './modules/sticky-nav';
import 'slick-carousel/slick/slick';

// Define Javascript is active by changing the body class
document.body.classList.remove('no-js');
document.body.classList.add('js');

document.addEventListener('DOMContentLoaded', () => {
  initAnchors();
  //backToTop();
  styleExternalLinks();
  initExternalLinkLabels();
  initA11ySkipLink();

  // Fit video embeds to container
  reframe('.wp-has-aspect-ratio iframe');

  $(window).scroll(function() {
    var scrollTop = $(window).scrollTop();
    var blockContentTop = $(".block-blog-content").offset().top;

    if (scrollTop >= blockContentTop) {
        $(".contents").addClass("fixed");
    } else {
        $(".contents").removeClass("fixed");
    }
  });

  // eslint-disable-next-line func-names
  $('.nav-link').click(function () {
    const pageId = $(this).attr('href');
    if (pageId.split('#')[1]) {
      $('html, body').animate({ scrollTop: $(pageId).offset().top }, 1000);
    }
  });

    var divheight = $('.package-wrapper').height();
    $('.included').css('height', 'calc(100% - '+divheight+'px)');
    

    $(window).on('resize', function(){
      var divheight = $('.package-wrapper').height();
      $('.included').css('height', 'calc(100% - '+divheight+'px)');
    });
    
    let currentPage = 1;
    const loadMoreButton = $('#load-more'); // Cache the button element
    loadMoreButton.on('click', function(e) {
        e.preventDefault();
        currentPage++; // Do currentPage + 1, because we want to load the next page
        if(window.location.hash) {
          var catHash = window.location.hash;
          var cat = catHash.replace('#','');
          var currentButton = $(`.cs-filter-button[data-name=${cat}]`);
          var categoryID = currentButton.data('category');
        }
        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: {
            action: 'focus_load_more',
            paged: currentPage,
            categoryfilter: categoryID,
            },
            success: function (res) {
              $('.case-studies').append(res.data.html);
              if (currentPage >= res.data.max) {
                loadMoreButton.hide(); // Hide the button if no more posts
              }
            }  
        });
    });

    let newsCurrentPage = 1;
    const newsLoadMoreButton = $('#news-load-more'); // Cache the button element
    newsLoadMoreButton.on('click', function(e) {
        e.preventDefault();
        newsCurrentPage++; // Do newsCurrentPage + 1, because we want to load the next page
        if(window.location.hash) {
          var catHash = window.location.hash;
          var cat = catHash.replace('#','');
          var currentButton = $(`.blog-filter-button[data-name=${cat}]`);
          var categoryID = '';
          if (currentButton.length) {
            categoryID = currentButton.data('category');
          }
        }
        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: {
            action: 'focus_news_load_more',
            paged: newsCurrentPage,
            categoryfilter: categoryID,
            },
            success: function (res) {
              $('.news-items').append(res.data.html);
              if (newsCurrentPage >= res.data.max) {
                newsLoadMoreButton.hide(); // Hide the button if no more posts
              }
            }  
        });
    });

    jQuery(window).on("load", function(){
      if(window.location.hash) {
        var catHash = window.location.hash;
        var cat = catHash.replace('#','');
        var currentButton = $(`.cs-filter-button[data-name=${cat}]`);
        $('.cs-filter-button').removeClass('active');
        currentButton.addClass('active');
        var categoryID = currentButton.data('category');
        $.ajax({
          url: '/wp-admin/admin-ajax.php',
          data: { action: 'focus_filter', categoryfilter: categoryID },
          type: 'POST',
          dataType: 'json',
          success: function(res) {
            if (res.data.max === 1) {
              $('.btn__wrapper').hide();
            }
            $('.case-studies').empty();
            $('.case-studies').append(res.data.html);
          }
        });
      }
    });

    
    $('.cs-filter-button').on('click', function() {
        currentPage = 1;
        $('.btn__wrapper').show();
        var categoryID = $(this).data('category');
        var category = $(this).data('name');
        $('.cs-filter-button').removeClass('active');
        $(this).addClass('active');
        window.history.replaceState(null, null, "#" + category);
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: { action: 'focus_filter', categoryfilter: categoryID },
            type: 'POST',
            dataType: 'json',
            success: function(res) {
              if (res.data.max === 1) {
                $('.btn__wrapper').hide();
              }
              $('.case-studies').empty();
              $('.case-studies').append(res.data.html);
            }
        });
    });

    $('.service-filter-button').on('click', function() {
      currentPage = 1;
      $('.btn__wrapper').show();
      var categoryID = $(this).data('category');
      $('.service-filter-button').removeClass('active');
      $(this).addClass('active');
      $.ajax({
          url: '/wp-admin/admin-ajax.php',
          data: { action: 'focus_service_filter', categoryfilter: categoryID },
          type: 'POST',
          dataType: 'json',
          success: function(res) {
            if (res.data.max === 1) {
              $('.btn__wrapper').hide();
            }
            $('.services').empty();
            $('.services').append(res.data.html);
          }
      });
  });

    $('.blog-filter-button').on('click', function() {
      currentPage = 1;
      $('.btn__wrapper').show();
      var categoryID = $(this).data('category');
      var category = $(this).data('name');
      $('.blog-filter-button').removeClass('active');
      $(this).addClass('active');
      window.history.replaceState(null, null, "#" + category);
      $.ajax({
          url: '/wp-admin/admin-ajax.php',
          data: { action: 'news_focus_filter', categoryfilter: categoryID },
          type: 'POST',
          dataType: 'json',
          success: function(res) {
            if (res.data.max === 1) {
              $('.btn__wrapper').hide();
            }
            $('.news-items').empty();
            $('.news-items').append(res.data.html);
          }
      });
  });

    $('.member-filter-button').on('click', function() {
        var department = $(this).data('department');
        $('.member-filter-button').removeClass('active');
        $(this).addClass('active');
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: { action: 'focus_team_filter', departmentfilter: department},
            type: 'POST',
            success: function(res) {
                $('.team-members').empty();
                $('.team-members').append(res);
            }
        });
    });

    // var amount = $('.large-showcase-slider').length;
    // alert(amount);

    const largeidName = 'large-showcase-slider-';
    var largeidValue = 1
    $('.large-showcase-slider').each(function(){
      $(this).attr('id', largeidName + largeidValue++);
    });

    const largearrowsidName = 'arrows-';
    var largearrowsidValue = 1
    $('.large-showcase-slider-navigation .arrows').each(function(){
      $(this).attr('id', largearrowsidName + largearrowsidValue++);
    });

    const largedotsidName = 'dots-';
    var largedotsidValue = 1
    $('.large-showcase-slider-navigation .dots').each(function(){
      $(this).attr('id', largedotsidName + largedotsidValue++);
    });

    const idName = 'showcase-slider-';
    var idValue = 1
    $('.showcase-slider').each(function(){
      $(this).attr('id', idName + idValue++);
    });

    const arrowsidName = 'arrows-';
    var arrowsidValue = 1
    $('.showcase-slider-navigation .arrows').each(function(){
      $(this).attr('id', arrowsidName + arrowsidValue++);
    });

    const dotsidName = 'dots-';
    var dotsidValue = 1
    $('.showcase-slider-navigation .dots').each(function(){
      $(this).attr('id', dotsidName + dotsidValue++);
    });

    const mobilearrowsidName = 'arrows-';
    var mobilearrowsidValue = 1
    $('.mobile-showcase-slider-navigation .arrows').each(function(){
      $(this).attr('id', mobilearrowsidName + mobilearrowsidValue++);
    });

    const mobiledotsidName = 'dots-';
    var mobiledotsidValue = 1
    $('.mobile-showcase-slider-navigation .dots').each(function(){
      $(this).attr('id', mobiledotsidName + mobiledotsidValue++);
    });

    setTimeout(function() { 
        var numSliders = $('.large-showcase-slider').length + 1;
      
        for (let i = 1; i < numSliders; i++) {
      
          var slider = $('#large-showcase-slider-' + i);
      
          var arrows = $('.large-showcase-slider-navigation #arrows-' + i );
      
          var dots = $('.large-showcase-slider-navigation #dots-' + i );
      
          slider.slick({
            autoplay: false,
            autoplaySpeed: 2000,
            arrows: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: arrows,
            appendDots: dots,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  dots: false,
                },
              }
            ]
          });
      
        }
      
        var smallNumSliders = $('.showcase-slider').length + 1;
      
        for (let x = 1; x < smallNumSliders; x++) {
      
          var smallSlider = $('#showcase-slider-' + x);
      
          var smallArrows = $('.showcase-slider-navigation #arrows-' + x );

          var mobilesmallArrows = $('.mobile-showcase-slider-navigation #arrows-' + x );
      
          var smallDots = $('.showcase-slider-navigation #dots-' + x );

          smallSlider.slick({
            autoplay: false,
            autoplaySpeed: 2000,
            arrows: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            appendArrows: smallArrows,
            appendDots: smallDots,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  appendArrows: mobilesmallArrows,
                  dots: false,
                },
              }
            ]
          });
      
          
        }
      
       
      
        
      }, 600);

    $('.row-heading').click(function(){
        var row = $(this).attr('id');
        var heading = '.row-heading#'+row;
        var content = '.row-content#'+row;
        $('.row-content').removeClass('active');
        $('.row-heading').removeClass('active');
        $(content).addClass('active');
        $(heading).addClass('active');
    });

    $('.custom-submenu select').on('click', function(){
      $('.select-wrapper').toggleClass('active');
    });

    $('.custom-submenu select').on('change', function(){
      var url = this.value;
      var target = $(this).find(':selected').attr('target');
      if(target === ''){
        target = '_self';
      }
      window.open(url, target);
    });

});
